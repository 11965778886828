/*eslint no-unused-vars: 0*/
// @ts-nocheck

import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import History from '../../history';
import { v4 as uuid4 } from 'uuid';
import Tool from '../../tools';
import DefaultTool from '../../tools/defaul-tool';
import * as fabric from 'fabric'
import ToJSONFields from "../../toJSONFields";
import sampleobject from './sampleobject.json';
// import { ConsoleLogger } from '@aws-amplify/core';
import Box from "@mui/material/Box";
import moment from "moment";
import { inlineSVGString } from '../../../../components/helperFunctions';

import { createIframeUsingJavascript } from '../../utils';
import { MAX_ZOOM, MIN_ZOOM, THEME_COLOR, THEME_OBJECT_BORDER, UPLOAD_API_URL } from '../../config/constants';
import { Button, IconButton, Popover, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { TwitterPicker } from "react-color";
import { checkTypesOfCanvasObject } from '../../../../components/utils';
import PaletteIcon from '@mui/icons-material/Palette';
import useHistory from '../../history';
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor';
import html2canvas from "html2canvas";
/**
 * Sketch Tool based on FabricJS for React Applications
 */



const colors = ['#000000', '#4D4D4D', '#999999', '#FFFFFF', '#C91500', '#FE9200', '#FFFA00', '#0F531A', '#BDFF00', '#00CCC7', '#00B2F7', '#2100F1', '#FA00FF'];

function SketchField(props, ref) {
  const { currentTool, _selectedTool } = props
  const [action, setAction] = useState(true);
  const [totalImagesHeight, setTotalImagesHeight] = useState(0);
  const [openOptions, setOpenOptions] = useState(false);
  const [optionsAnchor, setOptionsAnchor] = useState(null);
  const [selectedObject, setSelectedObject] = useState({});
  const [selectionTypeGroup, setSelectionTypeGroup] = useState(false);
  const [showObjectButton, setShowObjectButton] = useState(false);
  const [openObjectColorPicker, setOpenObjectColorPicker] = useState(false);
  const [showColorButton, setShowColorButton] = useState(false);

  const _fc = useRef(null);
  const _history = useRef(null);
  const _container = useRef(null);
  const _clipboard = useRef(null);
  const _canvas = useRef(null);

  const previousToolName = useRef(null)

  _history.current = useHistory(props.undoSteps);


  useImperativeHandle(ref, () => ({
    calculateMaxHeight,
    addAudioVideoTag,
    addIframeTag,
    onClickIframe,
    openIframeTag,
    addPdfViewerTag,
    onClickPdfViewer,
    openPdfViewer,
    onClickAudioVideo,
    addImg,
    _onObjectAdded,
    _onPathCreated,
    _onObjectMoving,
    _onObjectScaling,
    _onBackgroundColorChanged,
    _onPanChanged,
    _onNewZoomChanged,
    _onObjectRotating,
    _onObjectModified,
    _onMouseDoubleClick,
    _onObjectRemoved,
    _onBeforePathCreated,
    _onMouseDown,
    _onMouseMove,
    _onCanvasCleared,
    _onMouseOut,
    _onMouseUp,
    _resize,
    getCanvasDimension,
    _resizeWindow,
    changeBackgroundColor,
    setBackgroundColorForCanvas,
    setBackgroundImageForCanvas, changePan,
    zoomToFitObjects2,
    panAbsolute,
    zoomToFitObjects,
    newZoom,
    clear,
    hasSelection,
    constclearSelection,
    playAudioVideoTag,
    bringToFront,
    sendToBack,
    cloneObject,
    hideObject,
    changeObjectColor,
    showObject,
    openPopupForOptions,
    removeSelected,
    copy,
    paste,
    setBackgroundFromDataUrl,
    addPolyline,
    addRightAngledTriangle,
    addText,
    getObjectById,
    removeObjectById,
    modifyObj,
    changeObjectPositionOnDrag,
    addObj,
    removeObj,
    removePeerObjectsBySocketId,
    callEvent,
    createPath,
    createAnotherPath,
    setActionBox,
    undo,
    redo,
    canUndo,
    canRedo,
    _fc,
    _container,
    _history,
    addModifiedObjectToUndoList
  }));

  /**
   * Enable touch Scrolling on Canvas
   */
  const enableTouchScroll = () => {
    let canvas = _fc.current;
    if (canvas.allowTouchScrolling) return;
    canvas.allowTouchScrolling = true;
  };

  /**
   * Disable touch Scrolling on Canvas
   */
  const disableTouchScroll = () => {
    console.log(_fc)
    let canvas = _fc.current;
    if (canvas.allowTouchScrolling) {
      canvas.allowTouchScrolling = false;
    }
  };

  /**
   * Add an image as object to the canvas
   *
   * @param dataUrl the image url or Data Url
   * @param options object to pass and change some options when loading image, the format of the object is:
   *
   * {
   *   left: <Number: distance from left of canvas>,
   *   top: <Number: distance from top of canvas>,
   *   scale: <Number: initial scale of image>
   * }
   */

  /**
   *
   * @returns {object} -returns distance from top and left of the last object on the whiteboard from the origin as an object
   */
  const calculateMaxHeight = async () => {
    const canvas = _fc.current;
    const screenHeight = canvas.getHeight();
    const screenWidth = canvas.getWidth();
    var objects = canvas.getObjects().slice();
    objects.sort((a, b) => {
      if (a.top < b.top)
        return -1;
      if (a.top > b.top)
        return 1;
      return 0;
    })

    var left = 0;
    var maxY = 0;
    if (objects.length === 0)
      return {
        'totalHeight': maxY,
        'currentLeft': left
      };
    var viewportTransform = canvas.viewportTransform
    // console.log("current viewportTrasform", viewportTransform);
    var currentTop = viewportTransform[5]
    var currentLeft = viewportTransform[4];

    let lastObjHeight = 0;
    for (const obj of objects) {

      if ((obj.top + obj.height * obj.scaleY) > maxY) {
        left = obj.left
        lastObjHeight = obj.height * obj.scaleY
      }
      maxY = Math.max(obj.top + obj.height * obj.scaleY, maxY);
      // console.log('maxy', maxY)
    }

    // console.log('hey', {
    //   'totalHeight': maxY,
    //   'currentLeft': left
    // })
    return {
      'totalHeight': maxY,
      'currentLeft': left,
      'lastObjectHeight': lastObjHeight
    }
  }

  /**
   *
   * @param {string} uploadFileUrl - contains url of media to be played
   * @param {string} fileType - file type of media to identify if it is audio or video
   * @param {string} uploadFileName - file name of the media uploaded to the whiteboard
   * @param {object} options -any specific properties can be added by using options parameter when calling this function
   * This function adds audio video tag to the canvas
   */
  const addAudioVideoTag = async (uploadFileUrl, fileType, uploadFileName, options = {}) => {
    let canvas = _fc.current;
    let tagUrl;
    if (fileType === 'audio') {
      tagUrl = "https://files.teachmatter.com/uploads/9eebcf13-7a44-4e2a-a2e3-ddf0c9a989f8/audio_box.png"
    }
    else {
      tagUrl = 'https://files.teachmatter.com/uploads/9eebcf13-7a44-4e2a-a2e3-ddf0c9a989f8/video_box.png'
    }

    const oImg = await fabric.FabricImage.fromURL(tagUrl, { crossOrigin: 'anonymous' });

    let viewportTransform = canvas.viewportTransform;
    let screenTop = -viewportTransform[5];
    let screenLeft = -viewportTransform[4];
    const zoomLevel = viewportTransform[0]
    let imageScaleFactor = 0.8;
    if (imageScaleFactor * oImg.width < canvas.getWidth()) {
      screenLeft += (canvas.getWidth() - imageScaleFactor * oImg.width) / 2
    }
    else {
      imageScaleFactor = (canvas.getWidth() / oImg.width)
    }
    //console.log("width of client, image",canvas.getWidth(),oImg.width);
    //console.log("height of client, image",canvas.getHeight(),oImg.height);
    if (imageScaleFactor * oImg.height < canvas.getHeight()) {
      screenTop += (canvas.getHeight() - imageScaleFactor * oImg.height) / 2
    }
    let opts = {
      left: screenLeft * 1 / zoomLevel,
      top: screenTop * 1 / zoomLevel,
      scaleX: imageScaleFactor,
      scaleY: imageScaleFactor,
      selectable: true,
      evented: true,
      hasBorders: true,
      hasControls: true,
      hasRotatingPoint: true,
      shadow: {
        blur: 5, offsetX: 0, offsetY: 0
      },
      objectType: fileType,
      mediaFileUrl: uploadFileUrl,
    };
    Object.assign(opts, options);
    // oImg.typeOf = 'image'
    oImg.set(opts);
    // canvas.add(oImg);
    let text = new fabric.Text((uploadFileName).substring(0, 65), {
      fontFamily: 'Comic Sans',
      fontSize: 12,
      fill: 'black',
      textAlign: 'center',
    });
    text.set("top", oImg.top + oImg.height - 60);
    text.set("left", oImg.left);

    const oImgCenterPoint = oImg.getCenterPoint();
    let iconPlay = new fabric.Triangle({
      width: 50,
      height: 50,
      top: oImgCenterPoint.y,
      left: oImgCenterPoint.x,
      originX: 'center',
      originY: 'center',
      fill: 'black',
      angle: 90,
      buttonType: 'play'
    });
    iconPlay.set({ hoverCursor: 'pointer' });
    let group = new fabric.Group([oImg, text, iconPlay], {
      subTargetCheck: true,
      objectState: false,
    });
    group.set(opts);
    group.on('mousedown', onClickAudioVideo);
    canvas.add(group);
    canvas.viewportCenterObject(group)
    canvas.renderAll()


  }

  /**
 *
 * @param {string} uploadFileUrl - contains url of iframe to be opened
 * @param {string} iframeType - type of iframe which needs to be added
 * @param {object} options -any specific properties can be added by using options parameter when calling this function
 * This function adds iframe tag as a transparent object to the canvas which is superimposed by an iframe
 */
  const addIframeTag = (uploadFileUrl, iframeType, options = {}) => {
    let canvas = _fc.current;
    var viewportTransform = canvas.viewportTransform;
    var screenTop = -viewportTransform[5];
    var screenLeft = -viewportTransform[4];
    const zoomLevel = viewportTransform[0]


    var rectangle = new fabric.Rect({
      height: 500,
      width: 800,
      fill: 'black',
    });

    var imageScaleFactor = 0.8;
    if (imageScaleFactor * rectangle.width < canvas.getWidth()) {
      screenLeft += (canvas.getWidth() - imageScaleFactor * rectangle.width) / 2
    }
    else {
      imageScaleFactor = (canvas.getWidth() / rectangle.width)
    }
    //console.log("width of client, image",canvas.getWidth(),oImg.width);
    //console.log("height of client, image",canvas.getHeight(),oImg.height);
    if (imageScaleFactor * rectangle.height < canvas.getHeight()) {
      screenTop += (canvas.getHeight() - imageScaleFactor * rectangle.height) / 2
    }

    let optsRect = {
      left: screenLeft * 1 / zoomLevel,
      top: screenTop * 1 / zoomLevel,
      selectable: false,
      evented: true,
      hasBorders: true,
      hasControls: false,
      hasRotatingPoint: false,
      opacity: 0,
      shadow: {
        blur: 5, offsetX: 0, offsetY: 0
      },
      subTargetCheck: true,
      objectState: false,
      mediaFileUrl: uploadFileUrl,
      objectType: 'iframe',
      selectable: false,
      evented: true,
      hasBorders: true,
      hasControls: false,
      hasRotatingPoint: false,
    }
    rectangle.set(optsRect)
    // let group = new fabric.Group([rectangle], {

    // });
    if (iframeType === 'pdfViewer') {
      rectangle.set('iframeType', 'pdfViewer')
      rectangle.set('height', 600)
      rectangle.set('width', 700)
    }
    rectangle.on('mousedown', onClickIframe);
    canvas.add(rectangle);

    // console.log(canvas.viewportTransform)
    canvas.viewportCenterObject(rectangle)
    // console.log(canvas.viewportTransform)
    // let { offsetWidth = 10, clientHeight = 10 } = _container.current;
    // changePan({
    //   clientID: props.clientID,
    //   viewportTransform: canvas.viewportTransform,
    //   containerHeight: clientHeight,
    //   containerWidth: offsetWidth,
    // }, true)
    canvas.renderAll()

  }

  /**
   *
   * @param {object} e -  contains data of iframe object
   * this function opens an iframe on clicking the iframe obj
   */
  const onClickIframe = (e) => {
    // console.log('buttonclick', e)
    if (e.subTargets[0] && e.subTargets[0].objectType && e.subTargets[0].objectType === 'playIcon') {
      // console.log('buttonclick')
      openIframeTag()
    }
  }

  /**
   * this function gets the active object and gets it left and  top property from the canvas
     which is sent to the openiframeAndSendEvent function to open the iframe on the exact position
   */

  const openIframeTag = () => {
    let canvas = _fc.current;
    let activeObj = canvas.getActiveObject();
    // console.log(activeObj)
    // console.log('viewport', canvas.viewportTransform[4], canvas.viewportTransform[5])
    // console.log(canvas.getWidth(), canvas.getHeight())

    let left = activeObj.left * canvas.viewportTransform[0] - (-canvas.viewportTransform[4])
    let top = activeObj.top * canvas.viewportTransform[0] - (-canvas.viewportTransform[5])
    // console.log('calculated left top', left, top)
    // console.log(' left top', activeObj.left, activeObj.top)
    // console.log('viewport', canvas.viewportTransform)
    if (activeObj) {
      if (activeObj.objectType === 'iframe') {
        setTimeout(() => {
          props.openIframeAndSendEvent(activeObj.mediaFileUrl, left, top, activeObj.height * activeObj.scaleY * canvas.viewportTransform[0], activeObj.width * activeObj.scaleX * canvas.viewportTransform[0], activeObj.objectID)
        }, 500)

      }
    }

  }

  /**
   *
   * @param {string} uploadFileUrl - contains url of pdfViewer to be opened
   * @param {object} options -any specific properties can be added by using options parameter when calling this function
   */

  const addPdfViewerTag = async (uploadFileUrl, options = {}) => {
    let canvas = _fc.current;
    var viewportTransform = canvas.viewportTransform;
    var screenTop = -viewportTransform[5];
    var screenLeft = -viewportTransform[4];
    const zoomLevel = viewportTransform[0]


    var rectangle = new fabric.Rect({
      height: 500,
      width: 400,
      fill: 'black',
    });

    var imageScaleFactor = 0.8;
    if (imageScaleFactor * rectangle.width < canvas.getWidth()) {
      screenLeft += (canvas.getWidth() - imageScaleFactor * rectangle.width) / 2
    }
    else {
      imageScaleFactor = (canvas.getWidth() / rectangle.width)
    }
    //console.log("width of client, image",canvas.getWidth(),oImg.width);
    //console.log("height of client, image",canvas.getHeight(),oImg.height);
    if (imageScaleFactor * rectangle.height < canvas.getHeight()) {
      screenTop += (canvas.getHeight() - imageScaleFactor * rectangle.height) / 2
    }

    let optsRect = {
      left: screenLeft * 1 / zoomLevel,
      top: screenTop * 1 / zoomLevel,
      selectable: true,
      evented: true,
      hasBorders: true,
      hasControls: true,
      hasRotatingPoint: true,
      shadow: {
        blur: 5, offsetX: 0, offsetY: 0
      },
    }
    rectangle.set(optsRect)
    // Initiate a text instance
    const CenterPoint = rectangle.getCenterPoint();
    var text =
      new fabric.Text('Pdf', {
        fontSize: 25,
        originX: 'center',
        originY: 'center',
        top: CenterPoint.y + 100,
        left: CenterPoint.x,
        offsetY: -100,
        fill: 'white',
      });

    let playIconUrl = UPLOAD_API_URL + '/open_icon.png'
    const playIcon = await fabric.FabricImage.fromURL(playIconUrl, { crossOrigin: 'anonymous' });
    let opts = {
      width: 50,
      height: 50,
      top: CenterPoint.y,
      left: CenterPoint.x,
      originX: 'center',
      originY: 'center',
      fill: 'white',
      objectType: 'playIcon',
      hoverCursor: 'pointer'
    };
    playIcon.set(opts)
    let group = new fabric.Group([rectangle, text, playIcon], {
      subTargetCheck: true,
      objectState: false,
      mediaFileUrl: uploadFileUrl,
      objectType: 'pdfToView'
    });
    group.on('mousedown', onClickPdfViewer);
    canvas.add(group);

    canvas.viewportCenterObject(group)
    canvas.renderAll()

    // var iconPlay = new fabric.Triangle({
    //   width: 50,
    //   height: 50,
    //   top: CenterPoint.y,
    //   left: CenterPoint.x,
    //   originX: 'center',
    //   originY: 'center',
    //   fill: 'white',
    //   angle: 90,
    //   objectType: 'playIcon'
    // });
    // iconPlay.set({ hoverCursor: 'pointer' });



  }

  /**
 *
 * @param {object} e -  contains data of iframe object
 * this function opens pdf viewer on clicking the pdf obj
 */
  const onClickPdfViewer = (e) => {
    // console.log('buttonclick', e)
    if (e.subTargets[0] && e.subTargets[0].objectType && e.subTargets[0].objectType === 'playIcon') {
      // console.log('buttonclick')
      openPdfViewer()
      // props._selectTool({ target: { value: 'Pencil' } })
    }
  }

  /**
 * this function gets the active object and gets it left and  top property from the canvas
   which is sent to the openPdfViewerAndSendEvent function to open the pdfviewer on the exact position
 */
  const openPdfViewer = () => {
    let canvas = _fc.current;
    let activeObj = canvas.getActiveObject();
    // console.log(activeObj)
    // console.log('viewport', canvas.viewportTransform[4], canvas.viewportTransform[5])
    // console.log(canvas.getWidth(), canvas.getHeight())

    let left = activeObj.left * canvas.viewportTransform[0] - (-canvas.viewportTransform[4])
    let top = activeObj.top * canvas.viewportTransform[0] - (-canvas.viewportTransform[5])
    // console.log('calculated left top', left, top)
    // console.log(' left top', activeObj.left, activeObj.top)
    // console.log('viewport', canvas.viewportTransform)
    if (activeObj) {
      if (activeObj.objectType === 'pdfToView') {
        setTimeout(() => {
          props.openPdfViewerAndSendEvent(activeObj.mediaFileUrl, left, top, activeObj.height * activeObj.scaleY * canvas.viewportTransform[0], activeObj.width * activeObj.scaleX * canvas.viewportTransform[0], activeObj.objectID)
        }, 500)

      }
    }

  }

  /**
*
* @param {object} e -  contains data of iframe object
* this function opens media player clicking the audio/video obj
*/
  const onClickAudioVideo = (e) => {
    // console.log('buttonclick', e)
    if (e.subTargets[0] && e.subTargets[0].type && e.subTargets[0].type === 'triangle') {
      // console.log('buttonclick')
      playAudioVideoTag()
    }
  }

  /**
   *
   * @param {string} dataUrl - contains the url of the image to be added to the whiteboard
   * @param {boolean} isPdf - is used to identify if the image being sent to the function is just an image or part of a pdf
   * @param {object} options -any specific properties can be added by using options parameter when calling this function
   * This function reads the image using the url and adds it to the whiteboard
   */
  const addImg = async (dataUrl, isPdf = true, options = {}) => {
    let canvas = _fc.current;
    await new Promise(async (resolveP, rejectP) => {
      try {
        const oImg = await fabric.FabricImage.fromURL(dataUrl, { crossOrigin: 'anonymous' });
        let scaleFactorX = 0.9 * (canvas.getWidth() / oImg.width);
        let scaleFactorY = 1 * (canvas.getHeight() / oImg.height);
        let viewportTransform = canvas.viewportTransform;
        let screenTop = -viewportTransform[5];
        let screenLeft = -viewportTransform[4];
        const zoomLevel = viewportTransform[0]
        let imageScaleFactor = 0.8;
        if (imageScaleFactor * oImg.width < canvas.getWidth()) {
          screenLeft += (canvas.getWidth() - imageScaleFactor * oImg.width) / 2
        }
        else {
          imageScaleFactor = (canvas.getWidth() / oImg.width)
        }
        //console.log("width of client, image",canvas.getWidth(),oImg.width);
        //console.log("height of client, image",canvas.getHeight(),oImg.height);
        if (imageScaleFactor * oImg.height < canvas.getHeight()) {
          screenTop += (canvas.getHeight() - imageScaleFactor * oImg.height) / 2
        }
        let resObj = await calculateMaxHeight();
        // console.log(resObj)
        let maxHeight = resObj.totalHeight;
        let currentLeft = resObj.currentLeft;
        // console.log(maxHeight)
        let opts = {
          left: isPdf ? (currentLeft === 0 ? 80 : currentLeft) : screenLeft * 1 / zoomLevel,
          top: isPdf ? maxHeight + 85 : screenTop * 1 / zoomLevel,
          scaleX: imageScaleFactor,
          scaleY: imageScaleFactor,
          selectable: !isPdf,
          evented: !isPdf,
          hasBorders: !isPdf,
          hasControls: !isPdf,
          hasRotatingPoint: !isPdf,
          lockMovementY: isPdf,
          lockMovementX: isPdf,
          isPdf: isPdf,
          shadow: {
            blur: 5, offsetX: 0, offsetY: 0
          },
        };
        Object.assign(opts, options);
        // oImg.typeOf = 'image'
        oImg.set(opts);

        // oImg.setAttribute('crossOrigin', 'anonymous')
        console.log("img object", oImg)
        //console.log("scaleFactor is")
        canvas.add(oImg);

        canvas.renderAll()
        if (isPdf) {
          canvas.sendObjectToBack(oImg)
        }
        if (!isPdf) {
          canvas.viewportCenterObject(oImg)
        }
        canvas.renderAll()
        resolveP();

      }
      catch (err) {
        alert("File could not be uploaded")
        return;
      }

    })
    //console.log("CROS anonymous")
  };

  /**
* Action when an object is modified inside  the canvas
*/

  const addModifiedObjectToUndoList = (obj, sendWebSocketEvent = false) => {
    obj.__version += 1;
    let prevState = JSON.stringify(obj.currState);
    // Get the current state of the object as JSON
    let objState;
    objState = obj.toObject(ToJSONFields);
    let currState = { ...objState }; // Copy the current state to avoid reference issues
    // Record the current object state and update `prevState` and `currState`
    obj.actionDone = 'objectModified';
    // obj.set('prevState', JSON.parse(prevState));
    obj.set('currState', currState);
    // console.log(prevState, currState)

    let parsedPrevState = JSON.parse(prevState)
    parsedPrevState.objectID = currState.objectID;
    obj.prevState = parsedPrevState;
    if (!obj.lastAction || (obj.lastAction != 'undo' && obj.lastAction != 'redo')) {
      console.log('keeping in undo list', currState.objectID)
      console.log('keeping in undo list', obj.prevState)
      _history.current.keep(JSON.stringify(obj.toObject(ToJSONFields)));
    }
    else {
      obj.lastAction = 'ui'
    }
    if (sendWebSocketEvent) {
      props.onObjectModified({
        target: obj
      })
    }
  }

  const addReceivedModifiedObjectToUndoList = (obj) => {
    // obj.__version += 1;
    // obj.prevState.objectID = obj.objectID
    let prevState = JSON.stringify(obj.prevState);
    // Get the current state of the object as JSON
    let objState;
    objState = obj.toObject(ToJSONFields);
    let currState = { ...objState }; // Copy the current state to avoid reference issues
    // Record the current object state and update `prevState` and `currState`
    obj.actionDone = 'objectModified';
    // console.log(currState)
    let parsedPrevState = JSON.parse(prevState)
    parsedPrevState.objectID = currState.objectID;
    obj.set('prevState', parsedPrevState);
    obj.set('currState', currState);
    // console.log(prevState, currState)
    console.log('keeping in undo list', obj)
    _history.current.keep(JSON.stringify(obj.toObject(ToJSONFields)));

  }

  //fabric version 6
  const _onObjectModified = e => {
    console.log('on object modified', e)
    const { onObjectModified } = props;
    let obj = e.target;
    addModifiedObjectToUndoList(obj, true)
    // onObjectModified(e);
    setActionBox()
  };

  /**
   * Action when an object is added to the canvas
   */
  const _onObjectAdded = e => {
    // console.log('onObjectadded')
    if (!e.target.dontAdd) {
      // console.log('onObjectadded called')
      const { onObjectAdded } = props;
      e.target.transparentCorners = false;
      e.target.cornerColor = THEME_OBJECT_BORDER;
      e.target.cornerStyle = 'circle';
      if (e.target.objectID === undefined || !e.target.objectID) {
        e.target.objectID = uuid4()
      }

      let obj = e.target;
      // console.log(e, JSON.stringify(obj))
      let timeStamp = moment().toISOString()
      e.target.createdAt = timeStamp
      // console.log(timeStamp)
      if (obj.loadingSource !== 'file') {
        // if (!action) {
        //   setAction(true)
        //   return;
        // }

        obj.__version = 1;
        // console.log(e)
        // record current object state as json and save as originalState
        if (!obj.lastAction || (obj.lastAction != 'undo' && obj.lastAction != 'redo')) {
          let objState = obj.toObject(ToJSONFields)
          obj.__originalState = objState;
          let state = objState;
          obj.actionDone = 'objectAdded'
          obj.prevState = state;
          obj.currState = state;
          console.log('adding to undo list add obj', obj.toObject(ToJSONFields))
          _history.current.keep(JSON.stringify(obj.toObject(ToJSONFields)));
        }
        else {
          obj.lastAction = 'ui'
        }
        // console.log(e)
        // console.log(obj)
        onObjectAdded(e);
      }
      // let canvas = _fc.current;
      // console.log('current zoom level', canvas.getZoom())
      props.calculateNumberOfPages()
    }

  };

  /**
 * Action when an object is removed from the canvas
 */
  const _onObjectRemoved = e => {
    if (!e.target.dontAdd) {
      const { onObjectRemoved } = props;
      let obj = e.target;
      // console.log('_onObjectRemoved function ~SketchField.jsx',obj);
      if (obj.__removed) {
        obj.__version += 1;
      }
      else
        obj.__version = 0;

      if (obj.objectType === 'iframe') {
        let iframeElement = document.getElementById(obj.objectID)
        if (iframeElement) {
          iframeElement.remove()
        }
      }


      if (!obj.lastAction || (obj.lastAction != 'undo' && obj.lastAction != 'redo')) {
        // console.log('original obj initial', obj)
        let objState = obj.toObject(ToJSONFields)
        obj.__originalState = objState;
        let state = objState;
        obj.actionDone = 'objectRemoved'
        obj.prevState = state;
        obj.currState = state;

        // console.log('original obj', obj)
        // console.log('original obj string', JSON.stringify(obj))
        let top = obj.top;
        let left = obj.left
        let atop = obj.aCoords.bl.y;
        let aleft = obj.aCoords.bl.x;
        // console.log('original top and left', top, left)
        // console.log('acords top and left', atop, aleft)
        // console.log('adding to undo list remove obj', obj.toObject(ToJSONFields))
        let objToAdd = obj.toObject(ToJSONFields);
        _history.current.keep(JSON.stringify(obj.toObject(ToJSONFields)));
      }
      else {
        obj.lastAction = 'ui'
      }

      onObjectRemoved(e);
      props.calculateNumberOfPages()
    }

  };


  const _onPathCreated = e => {
    const { onPathCreated } = props;
    // if (!state.action) {
    //   setState({ action: true });
    //   return;
    // }
    // let obj = e.target;
    // obj.__version = 1;
    // // record current object state as json and save as originalState
    // let objState = obj.toJSON();
    // obj.__originalState = objState;
    // let state = JSON.stringify(objState);
    // // object, previous state, current state
    // _history.current.keep([obj, state, state]);
    onPathCreated(e);
  };

  /**
   * Action when an object is moving around inside the canvas
   */
  const _onObjectMoving = e => {
    const { onObjectMoving } = props;
    onObjectMoving(e);
    let actionsBox = document.getElementById('object-actions-box');
    if (actionsBox) {
      actionsBox.style.display = 'none';
    }
  };

  /**
   * Action when an object is scaling inside the canvas
   */
  const _onObjectScaling = e => {
    const { onObjectScaling } = props;
    let actionsBox = document.getElementById('object-actions-box');
    if (actionsBox) {
      actionsBox.style.display = 'none';
    }
    if (onObjectScaling) {
      onObjectScaling(e)
    }
  };

  const _onBackgroundColorChanged = e => {
    const { onBackgroundColorChanged } = props;
    onBackgroundColorChanged(e);
  }

  /**
 * Action when a pan event is called on the canvas
 */
  const _onPanChanged = e => {
    // console.log(e)
    const { onPanChanged } = props;
    const objects = _fc.current.getObjects().slice()
    for (const obj of objects) {
      if (obj.objectType === 'iframe') {

        let iframeElement = document.getElementById(obj.objectID)
        // console.log(document.getElementById(obj.objectID))
        let positionDimensionObj = props.findObjectAndGetPositionAndDimensions(obj.objectID)
        // console.log(positionDimensionObj)
        iframeElement.style.top = positionDimensionObj.top + 'px'
        iframeElement.style.left = positionDimensionObj.left + 'px'
      }

    }
    setActionBox(false)
    onPanChanged(e);
  }

  /**
* Action when a new zoom event is called on the canvas
*/
  const _onNewZoomChanged = e => {
    const { onNewZoomChanged } = props;
    onNewZoomChanged(e);
    setActionBox(false)
  }

  /**
   * Action when an object is rotating inside the canvas
   */
  const _onObjectRotating = e => {
    const { onObjectRotating } = props;
    if (onObjectRotating) {
      onObjectRotating(e);
    }
    let actionsBox = document.getElementById('object-actions-box');
    if (actionsBox) {
      actionsBox.style.display = 'none';
    }
  };



  /**
* Action when the mouse is double clicked on the canvas
*/
  const _onMouseDoubleClick = e => {
    // console.log(e)
    // console.log(_fc.current)
    let canvas = _fc.current;
    // let activeObj = canvas.getActiveObject();
    // console.log(activeObj)
    var target = canvas.findTarget(e);
    if (target) {
      console.log(target)
    }
  };






  const _onBeforePathCreated = e => {
    const { onBeforePathCreated } = props;

    onBeforePathCreated(e);
  };





  /**
   * Action when the mouse button is pressed down
   */
  const _onMouseDown = e => {
    const { onMouseDown } = props;
    if (onMouseDown) {
      onMouseDown(e)
    }
    _selectedTool.current.doMouseDown(e);
    // if (e.target && e.target.objectType) {
    //   console.log(e.target)
    // }
  };

  /**
   * Action when the mouse cursor is moving around within the canvas
   */
  const _onMouseMove = e => {
    const { onMouseMove } = props;
    // console.log("mouse" + JSON.stringify(e))
    const obj = e.target
    let canvas = _fc.current
    // console.log(previousToolName)
    if (obj && obj.objectType && (obj.objectType === 'audio' || obj.objectType === 'video' || obj.objectType === 'pdfToView')) {

      if (!canvas.getActiveObject()) {
        previousToolName.current = currentTool.current
        props._customToolbar.current._selectTool({ target: { value: "Select" } })
        // console.log('audio/video object')
        canvas.setActiveObject(obj);
        canvas.renderAll()
      }

    }
    else {
      if (previousToolName.current !== 'Select' && canvas.getActiveObject() && canvas.getActiveObject().objectType && (canvas.getActiveObject().objectType === 'audio' || canvas.getActiveObject().objectType === 'video' || canvas.getActiveObject().objectType === 'pdfToView')) {
        //fabric version 6
        canvas.discardActiveObject()
        canvas.requestRenderAll()
        // console.log(state.previousToolName)
        props._customToolbar.current._selectTool({ target: { value: previousToolName.current } })
      }
    }
    if (_selectedTool.current) {
      _selectedTool.current.doMouseMove(e);
    }
    if (onMouseMove) {
      onMouseMove(e)
    }
    // onMouseMove(e);
  };

  /**
 * Action when a clear all event is called on the canvas
 */
  const _onCanvasCleared = e => {
    const { onCanvasCleared } = props;
    // console.log("_onCanvasCleared sketchField.js is called");


    onCanvasCleared(e);
  };

  /**
   * Action when the mouse cursor is moving out from the canvas
   */
  const _onMouseOut = e => {
    const { onMouseOut } = props;
    if (_selectedTool.current) {
      _selectedTool.current.doMouseOut(e);
    }

    if (onMouseOut) {
      onMouseOut(e)
    }
    // onMouseOut(e);
  };

  /**
 * Action when the mouse is removed from pressed/tapped down state on the canvas
 */
  const _onMouseUp = e => {
    const { onMouseUp } = props;

    _selectedTool.current.doMouseUp(e, props.clientID);
    // console.log("mouse up is called", e);
    // Update the final state to new-generated object
    // Ignore Path object since it would be created after mouseUp
    // Assumed the last object in canvas.getObjects() in the newest object

    // console.log(currentTool)
    if (currentTool.current === "Pan") {
      const canvas = _fc.current;
      let { offsetWidth = 0, clientHeight = 0 } = _container.current;
      var newObj = {
        clientID: props.clientID,
        eventType: "pan:changed",
        viewportTransform: canvas.viewportTransform,
        containerWidth: offsetWidth,
        containerHeight: clientHeight,
      }
      canvas.fire("pan:changed", newObj);
    }
    // else if (
    //   currentTool.current === "Arrow"
    // ) {
    //   const canvas = _fc.current;
    //   // console.log("canvas points", canvas.getPointer(e))

    //   const objects = canvas.getObjects();
    //   const newObj = objects[objects.length - 1];
    //   newObj.dontAdd = false;
    //   _onObjectAdded({
    //     target: newObj
    //   })
    // }
    else if (currentTool.current === "Line" ||
      currentTool.current === "DashedLine" ||
      currentTool.current === "Rectangle" ||
      currentTool.current === "Circle" ||
      currentTool.current === "Arrow"
    ) {
      const canvas = _fc.current;
      // console.log("canvas points", canvas.getPointer(e))

      const objects = canvas.getObjects();
      const newObj = objects[objects.length - 1];
      newObj.dontAdd = false;
      _onObjectAdded({
        target: newObj
      })
    }

    if (onMouseUp) {
      onMouseUp(e);
    }

  };

  /**
   *
   * Track the resize of the window and update our state
   * @param {object} e - the resize event
   * @param {number} canvasWidth - width of the canvas
   * @param {number} canvasHeight - height of the canvas
   */

  const _resize = (canvasWidth = null, canvasHeight = null) => {
    let { widthCorrection, heightCorrection } = props;
    let canvas = _fc.current;
    // console.log(_fc)
    let { offsetWidth, clientHeight } = _container.current;
    // console.log('height', clientHeight - clientHeight)
    // console.log('width', offsetWidth - offsetWidth)
    let prevWidth = canvasWidth || canvas.getWidth();
    let prevHeight = canvasHeight || canvas.getHeight();
    let wfactor = ((offsetWidth - widthCorrection) / prevWidth).toFixed(2);
    let hfactor = ((clientHeight - heightCorrection) / prevHeight).toFixed(2);
    canvas.setWidth(offsetWidth - widthCorrection);
    canvas.setHeight(clientHeight - heightCorrection);
    if (canvas.backgroundImage) {
      // Need to scale background images as well
      let bi = canvas.backgroundImage;
      bi.width = bi.width * wfactor;
      bi.height = bi.height * hfactor;
    }
    let objects = canvas.getObjects();
    for (let i in objects) {
      let obj = objects[i];
      let scaleX = obj.scaleX;
      let scaleY = obj.scaleY;
      let left = obj.left;
      let top = obj.top;
      let tempScaleX = scaleX * wfactor;
      let tempScaleY = scaleY * hfactor;
      let tempLeft = left * wfactor;
      let tempTop = top * hfactor;
      obj.scaleX = tempScaleX;
      obj.scaleY = tempScaleY;
      obj.left = tempLeft;
      obj.top = tempTop;
      obj.setCoords();
    }
    // console.log(' height', clientHeight - heightCorrection)
    // console.log(' width', offsetWidth - widthCorrection)
    canvas.renderAll();
    canvas.calcOffset();
  };

  const getCanvasDimension = () => {
    let { offsetWidth, clientHeight } = _container.current;
    // console.log('dimensions', offsetWidth, clientHeight)
    return { offsetWidth, clientHeight }
  }

  const _resizeWindow = (e, canvasWidth = null, canvasHeight = null) => {
    if (e) e.preventDefault();
    let canvas = _fc.current;
    let { offsetWidth, clientHeight } = _container.current;
    let { widthCorrection, heightCorrection } = props;
    let prevWidth = canvasWidth || canvas.getWidth();
    let prevHeight = canvasHeight || canvas.getHeight();
    let wfactor = ((offsetWidth - widthCorrection) / prevWidth).toFixed(2);
    let hfactor = ((clientHeight - heightCorrection) / prevHeight).toFixed(2);

    // ***important*** DO NOT DELETE
    // This code is repeated on purpose check below for more information
    canvas.setWidth(offsetWidth - widthCorrection);
    canvas.setHeight(clientHeight - heightCorrection);
    if (canvas.backgroundImage) {
      // Need to scale background images as well
      let bi = canvas.backgroundImage;
      bi.width = bi.width * wfactor;
      bi.height = bi.height * hfactor;
    }


    // canvas.setWidth(offsetWidth);
    // canvas.setHeight(clientHeight);
    // console.log(offsetWidth, clientHeight)
    // console.log(canvas.getWidth(), canvas.getHeight())
    // if (canvas.backgroundImage) {
    //   // Need to scale background images as well
    //   let bi = canvas.backgroundImage;
    //   bi.width = bi.width * wfactor;
    //   bi.height = bi.height * hfactor;
    // }
    let objects = canvas.getObjects();
    for (let i in objects) {
      let obj = objects[i];
      let tempObj = props.scaleJSON(obj)
      objects[i] = tempObj
      objects[i].setCoords();
      // let dimensions = getCanvasDimension()
      // canvas.setWidth(dimensions.offsetWidth);
      // canvas.setHeight(dimensions.clientHeight);
    }
    canvas.renderAll();
    canvas.calcOffset();
    //canvas width and height are set two times because the first time they are received the values might be incorect
    //due to a bug when resizing from fullscreen mode where the width received is 15-16 px less than the original values

    let dimensions = getCanvasDimension()
    // console.log(prevWidth, prevHeight)
    canvas.setWidth(dimensions.offsetWidth);
    canvas.setHeight(dimensions.clientHeight);
    let tempViewportTransform = canvas.viewportTransform
    tempViewportTransform[5] *= dimensions.clientHeight / prevHeight
    tempViewportTransform[4] *= dimensions.offsetWidth / prevWidth
    // console.log(tempViewportTransform)
    // console.log(dimensions)
    changePan({
      clientID: props.clientID,
      viewportTransform: tempViewportTransform,
    }, false)
  };



  /**
  * Sets the background for this sketch
   * @param {object} e - an object which containms the color and background pattern to be set using this function
   * @returns nothing
   */
  const changeBackgroundColor = e => {
    if (!e) return;
    let canvas = _fc.current;
    // console.log("changeBackgroundColor sketchField", e);
    // setBackgroundColorForCanvas(canvas, e.color)

    if (e.backgroundPattern) {
      let colorOfPattern = '#9F9EB2';
      if (e.patternColor) {
        colorOfPattern = e.patternColor
      }
      let tileSvgString
      if (e.patternType === 'grid') {

        tileSvgString = `<svg  style="background-color:${e.color}" width="600" height="800" xmlns="http://www.w3.org/2000/svg">
        <!-- Background -->
        <rect width="100%" height="100%" />

        <!-- Border on the right side and top -->
        <rect x="0" y="0" width="100%" height="100%" fill="${e.color}" stroke="${colorOfPattern}" stroke-width="1"/>

        <!-- Horizontal Lines -->
        <g stroke="${colorOfPattern}" stroke-width="1">
          <line x1="0" y1="50" x2="600" y2="50"/>
          <line x1="0" y1="100" x2="600" y2="100"/>
          <line x1="0" y1="150" x2="600" y2="150"/>
          <line x1="0" y1="200" x2="600" y2="200"/>
          <line x1="0" y1="250" x2="600" y2="250"/>
          <line x1="0" y1="300" x2="600" y2="300"/>
          <line x1="0" y1="350" x2="600" y2="350"/>
          <line x1="0" y1="400" x2="600" y2="400"/>
          <line x1="0" y1="450" x2="600" y2="450"/>
          <line x1="0" y1="500" x2="600" y2="500"/>
          <line x1="0" y1="550" x2="600" y2="550"/>
          <line x1="0" y1="600" x2="600" y2="600"/>
          <line x1="0" y1="650" x2="600" y2="650"/>
          <line x1="0" y1="700" x2="600" y2="700"/>
          <line x1="0" y1="750" x2="600" y2="750"/>
        </g>

        <!-- Vertical Lines -->
        <g stroke="${colorOfPattern}" stroke-width="1">
          <line x1="50" y1="0" x2="50" y2="800"/>
          <line x1="100" y1="0" x2="100" y2="800"/>
          <line x1="150" y1="0" x2="150" y2="800"/>
          <line x1="200" y1="0" x2="200" y2="800"/>
          <line x1="250" y1="0" x2="250" y2="800"/>
          <line x1="300" y1="0" x2="300" y2="800"/>
          <line x1="350" y1="0" x2="350" y2="800"/>
          <line x1="400" y1="0" x2="400" y2="800"/>
          <line x1="450" y1="0" x2="450" y2="800"/>
          <line x1="500" y1="0" x2="500" y2="800"/>
          <line x1="550" y1="0" x2="550" y2="800"/>
        </g>
      </svg>`
        setBackgroundImageForCanvas(canvas, inlineSVGString(tileSvgString))
        //     canvas.setBackgroundColor({ source: inlineSVGString(tileSvgString) }, () => setTimeout(() => canvas.requestRenderAll(), 0));
      }
      else if (e.patternType === 'dotted-grid') {
        let size = 20
        let gap = 30
        const circlePositions = [[gap, 0], [0, 0], [gap, gap], [0, gap]]
        const circleStyle = `fill:${colorOfPattern};stroke:#9d5867;stroke-width:0;`
        let r = 2
        tileSvgString = `<svg style="background-color:${e.color}" width="${size}" height="${size}" viewBox="0 0 ${size} ${size}" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs/><g>
        ${circlePositions.map(([cx, cy]) => `<circle style="${circleStyle}" cx="${cx}" cy="${cy}" r="${r}"/>`).join("\n")}
    </g></svg>`
        setBackgroundImageForCanvas(canvas, inlineSVGString(tileSvgString))
        //    canvas.setBackgroundColor({ source: inlineSVGString(tileSvgString) }, () => setTimeout(() => canvas.requestRenderAll(), 0));
      }
      else if (e.patternType === 'horizontal-lines') {
        tileSvgString = `<svg width="600" height="400" xmlns="http://www.w3.org/2000/svg">
        <!-- Background -->
        <rect width="100%" height="100%" fill="${e.color}"/>

        <!-- Lines -->
        <g stroke="${colorOfPattern}" stroke-width="1">
          <line x1="0" y1="50" x2="600" y2="50"/>
          <line x1="0" y1="100" x2="600" y2="100"/>
          <line x1="0" y1="150" x2="600" y2="150"/>
          <line x1="0" y1="200" x2="600" y2="200"/>
          <line x1="0" y1="250" x2="600" y2="250"/>
          <line x1="0" y1="300" x2="600" y2="300"/>
          <line x1="0" y1="350" x2="600" y2="350"/>
        </g>

        <!-- Border at the bottom -->
        <line x1="0" y1="400" x2="600" y2="400" stroke="${colorOfPattern}" stroke-width="2"/>
      </svg>`
        // canvas.setBackgroundColor({ source: inlineSVGString(tileSvgString) }, () => setTimeout(() => canvas.requestRenderAll(), 0));
        setBackgroundImageForCanvas(canvas, inlineSVGString(tileSvgString))
      }
      else if (e.patternType === 'None') {
        setBackgroundColorForCanvas(canvas, e.color)
        // canvas.setBackgroundColor(e.color, () => canvas.renderAll());
      }
      else {
        let size = 20
        let gap = 30
        const circlePositions = [[gap, 0], [0, 0], [gap, gap], [0, gap]]
        const circleStyle = `fill:${colorOfPattern};stroke:#9d5867;stroke-width:0;`
        let r = 2
        tileSvgString = `<svg style="background-color:${e.color}" width="${size}" height="${size}" viewBox="0 0 ${size} ${size}" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs/><g>
        ${circlePositions.map(([cx, cy]) => `<circle style="${circleStyle}" cx="${cx}" cy="${cy}" r="${r}"/>`).join("\n")}
    </g></svg>`
        setBackgroundImageForCanvas(canvas, inlineSVGString(tileSvgString))
        //    canvas.setBackgroundColor({ source: inlineSVGString(tileSvgString) }, () => setTimeout(() => canvas.requestRenderAll(), 0));
      }

    }

    else {
      setBackgroundColorForCanvas(canvas, e.color)
      // canvas.setBackgroundColor(e.color, () => canvas.renderAll());
    }

    canvas.fire("backgroundColor:changed", e);
  };

  //fabric version 6
  const setBackgroundColorForCanvas = (canvas, color) => {
    // console.log(canvas)
    canvas.backgroundColor = color;
    canvas.requestRenderAll()
    // console.log('changing bg', color)
  }

  const setBackgroundImageForCanvas = async (canvas, img) => {
    let imgNew = fabric.getFabricDocument().createElement('img');
    imgNew.src = img;
    canvas.backgroundColor = new fabric.Pattern({
      repeat: 'repeat',
      source: imgNew,
    });
    // console.log(canvas)
    canvas.requestRenderAll()
    // console.log('changing bg', img)
  }

  /**
   *
   * @param {object} obj -contains the new viewport to which the canvas will be panned
   * @param {boolean} firePanChangedEvent - to decide whether to fire the pan event to the other clients or not
   * @returns
   */
  const changePan = (obj, firePanChangedEvent = true) => {
    if (obj.viewportTransform) {
      _fc.current.setViewportTransform(obj.viewportTransform)
      _fc.current.renderAll();
      props.identifyCurrentPage()
      props.calculateNumberOfPages()
      if (firePanChangedEvent) {
        _fc.current.fire("pan:changed", obj);
      }
      else {
        return obj
      }
    }

  }

  /**
   * Zoom the drawing by the factor specified
   *
   * The zoom factor is a percentage with regards the original, for example if factor is set to 2
   * it will double the size whereas if it is set to 0.5 it will half the size
   *
   * @param factor the zoom factor
   */

  const zoomToFitObjects2 = function (zoomByWidth) {
    let canvas = _fc.current;
    if (canvas.getObjects().length < 1) {
      return;
    }
    let x1 = Math.min(
      ...canvas.getObjects().map((obj) => obj.left)
    );
    let y1 = Math.min(
      ...canvas.getObjects().map((obj) => obj.top)
    );
    let x2 = Math.max(
      ...canvas.getObjects().map((obj) => obj.left)
    );
    let y2 = Math.max(
      ...canvas.getObjects().map((obj) => obj.top)
    );
    let height = Math.abs(y1) + Math.abs(y2);
    let width = Math.abs(x1) + Math.abs(x2);
    canvas.setZoom(1);
    const x = (x1 + (width / 2)) - (canvas.width / 2);
    const y = (y1 + (height / 2)) - (canvas.height / 2);

    const heightDist = canvas.getHeight() - height;
    const widthDist = canvas.getWidth() - width;
    let groupDimension = 0;
    let canvasDimension = 0;
    if (zoomByWidth) {
      panAbsolute(x, 10)
      groupDimension = width;
      canvasDimension = canvas.getWidth();
    }
    else {
      panAbsolute(x, y)
      if (heightDist < widthDist) {
        groupDimension = height;
        canvasDimension = canvas.getHeight();
      } else {
        groupDimension = width;
        canvasDimension = canvas.getWidth();
      }
    }

    const zoom = (canvasDimension / groupDimension) * 0.7;
    if (zoom >= MIN_ZOOM && zoom <= MAX_ZOOM) {
      props.onNewZoomAbsolute(zoom)
    }
    else if (zoom < MIN_ZOOM) {
      props.onNewZoomAbsolute(MIN_ZOOM)
    } else if (zoom > MAX_ZOOM) {
      props.onNewZoomAbsolute(MAX_ZOOM)
    }

    // canvas.zoomToPoint({ x: canvas.width / 2, y: canvas.height / 2 }, zoom);
    // canvas.renderAll();
  };

  const panAbsolute = (x, y) => {
    let canvas = _fc.current;
    if (x && y) {
      canvas.absolutePan({ x: x, y: y });
    }
    else {
      canvas.absolutePan({ x: x });
    }

    canvas.renderAll();
    setActionBox()
    props.firePanEvent();
    props.calculateNumberOfPages()
  }

  const zoomToFitObjects = (zoomByWidth) => {
    let canvas = _fc.current;
    //first check if there are any elemnts to zoom to
    if (canvas.getObjects().length < 1) {
      return;
    }
    // reset zoom so pan actions work as expected
    canvas.setZoom(1);
    //group all the objects
    const group = new fabric.Group(canvas.getObjects());
    //find the centre of the group on the canvas
    let x = (group.left + (group.width / 2)) - (canvas.width / 2);
    let y = (group.top + (group.height / 2)) - (canvas.height / 2);
    if (zoomByWidth) {
      y = group.top
    }
    //and pan to it
    panAbsolute(x, y)

    const heightDist = canvas.getHeight() - group.height;
    const widthDist = canvas.getWidth() - group.width;
    let groupDimension = 0;
    let canvasDimension = 0;

    if (zoomByWidth) {
      groupDimension = group.width;
      canvasDimension = canvas.getWidth();
    }
    else {
      if (heightDist < widthDist) {
        //height is the reference so need the height to scale to be nearly the height of the canvas
        groupDimension = group.height;
        canvasDimension = canvas.getHeight();
      } else {
        //width is the reference so need the width to scale to be nearly the width of the canvas
        groupDimension = group.width;
        canvasDimension = canvas.getWidth();
      }
    }

    //work out how to scale the group to match the canvas size (then only make it zoom 80% of the way)
    const zoom = (canvasDimension / groupDimension) * 0.8;
    //we've already panned the canvas to the centre of the group, so now zomm using teh centre of teh canvas as teh reference point
    if (zoom >= MIN_ZOOM && zoom <= MAX_ZOOM) {
      props.onNewZoomAbsolute(zoom)
    }
    else if (zoom < MIN_ZOOM) {
      if (!zoomByWidth) {
        panAbsolute(x, group.top)
      }
      props.onNewZoomAbsolute(MIN_ZOOM)

    } else if (zoom > MAX_ZOOM) {
      if (!zoomByWidth) {
        panAbsolute(x, group.top)
      }
      props.onNewZoomAbsolute(MAX_ZOOM)
    }

  }

  /**
   * This function sets the new zoom level of the canvas and updates the size and position of all the iframes accordingly
   * @param {object} obj -contains the new zoom level to which the canvas will be set
   * @param {boolean} fireZoomChangedEvent - to decide whether to fire the pan event to the other clients or not
   */
  const newZoom = (obj, fireZoomChangedEvent = true) => {
    if (obj.zoomLevel) {
      let canvas = _fc.current;
      // console.log('setting zoom level with factor', obj.zoomLevel)
      canvas.zoomToPoint(new fabric.Point(canvas.width / 2, canvas.height / 2), obj.zoomLevel);
      // console.log('after setting zoom level', canvas.getZoom())
      canvas.renderAll();
      const objects = _fc.current.getObjects().slice()
      for (const obj of objects) {
        if (obj.objectType === 'iframe') {

          let iframeElement = document.getElementById(obj.objectID)
          // console.log(document.getElementById(obj.objectID))
          let positionDimensionObj = props.findObjectAndGetPositionAndDimensions(obj.objectID)
          // console.log(positionDimensionObj)
          iframeElement.style.top = positionDimensionObj.top + 'px'
          iframeElement.style.left = positionDimensionObj.left + 'px'
          iframeElement.style.width = positionDimensionObj.width + 'px';
          iframeElement.style.height = positionDimensionObj.height + 'px';
        }

      }
      if (fireZoomChangedEvent) {
        canvas.fire("newZoom:changed", obj);
      }
      props.calculateNumberOfPages()
      props.identifyCurrentPage()
    }
  };
  /**
   * Perform an undo operation on canvas, if it cannot undo it will leave the canvas intact
   */
  const undo = () => {
    let history = _history.current;
    if (history) {
      // console.log(history)
      if (history.canUndo()) {
        // console.log('undolist1', history.getUndoList())
        // console.log('redolist1', history.getRedoList())
        // let objTemp = history.undo();
        let objTemp = history.undo();
        let obj = JSON.parse(objTemp)
        // console.log('undolist2', history.getUndoList())
        // console.log('redolist2', history.getRedoList())
        if (obj.actionDone === 'objectRemoved') {
          // setAction(false);
          obj.clientID = undefined;
          obj.__removed = false;
          obj.lastAction = 'undo'
          // console.log('calling add obj')
          addObj(obj)

        } else if (obj.actionDone === 'objectAdded') {
          // console.log('undo remove')
          obj.removedBy = props.clientID;
          obj.lastAction = 'undo'
          removeObj(obj)
        } else if (obj.actionDone === 'objectModified') {
          obj.__version -= 1;
          // console.log(obj)
          let newObj = obj.prevState
          newObj.lastAction = 'undo'
          modifyObj(newObj, true)
        }


      }

    }
  };

  /**
 * Perform a redo operation on canvas, if it cannot redo it will leave the canvas intact
 */
  const redo = () => {
    let history = _history.current;
    if (history) {
      // console.log(history)
      if (history.canRedo()) {
        // console.log('undolist1', history.getUndoList())
        // console.log('redolist1', history.getRedoList())
        let canvas = _fc.current;
        //noinspection Eslint
        // console.log(history)
        // let objTemp = history.redo();
        let objTemp = history.redo();
        let obj = JSON.parse(objTemp)
        // console.log('undolist2', history.getUndoList())
        // console.log('redolist2', history.getRedoList())
        if (obj.actionDone === 'objectAdded') {
          // setAction(false)
          obj.clientID = undefined;
          obj.lastAction = 'redo'
          addObj(obj)
          // setState({ action: false }, () => {

          // });
        } else if ((obj.actionDone === 'objectRemoved')) {
          obj.removedBy = props.clientID;
          obj.lastAction = 'redo'
          removeObj(obj)
        }
        else if ((obj.actionDone === 'objectModified')) {
          obj.__version += 1;
          // console.log(obj.currState)
          let newObj = obj.currState
          newObj.lastAction = 'undo'
          modifyObj(newObj, true)
        }
        // obj.setCoords();
        // canvas.renderAll();

      }
    }
  };


  const setActionBox = (displayToggle = true) => {
    if (!props.isViewer) {
      let actionsBox = document.getElementById('object-actions-box');
      if (actionsBox) {
        let canvas = _fc.current;
        let activeObj = canvas.getActiveObject();
        // console.log(activeObj)
        if (activeObj) {
          if (activeObj._objects) {
            // console.log('group')
            if (!selectionTypeGroup) {
              setSelectionTypeGroup(true)
            }
          }
          else {
            // console.log('single')
            if (selectionTypeGroup) {
              setSelectionTypeGroup(false)
            }
            // console.log(activeObj.notVisibleTo)
            if ((activeObj.notVisibleTo) && (activeObj.notVisibleTo).includes('Student')) {
              setShowObjectButton(true)

            }
            else {
              setShowObjectButton(false)
            }

            if (activeObj.__originalState && checkTypesOfCanvasObject(activeObj.__originalState.type)) {
              setShowColorButton(true)

            }
            else {
              setShowColorButton(false)
            }


          }

          let left = activeObj.left * canvas.viewportTransform[0] - (-canvas.viewportTransform[4])
          let top = activeObj.top * canvas.viewportTransform[0] - (-canvas.viewportTransform[5])
          // console.log(top, left)
          // console.log(minX, minY)
          if (displayToggle) {
            actionsBox.style.display = 'flex';
          }
          actionsBox.style.left = (left - 60) + 'px';
          actionsBox.style.top = (top) + 'px';
        }
      }
    }
  }

  const _onSelection = () => {
    // console.log('selected')
    setActionBox()
  }


  const _onSelectionChanged = () => {
    // console.log('selected changed')
    setActionBox()
  }

  const _onSelectionCleared = () => {
    console.log('selected cleared')
    let actionsBox = document.getElementById('object-actions-box');
    if (actionsBox) {
      actionsBox.style.display = 'none';
    }

  }

  /**
   * Delegation method to check if we can perform an undo Operation, useful to disable/enable possible buttons
   *
   * @returns {*} true if we can undo otherwise false
   */
  const canUndo = () => {
    return _history.current.canUndo();
  };

  /**
   * Delegation method to check if we can perform a redo Operation, useful to disable/enable possible buttons
   *
   * @returns {*} true if we can redo otherwise false
   */
  const canRedo = () => {
    return _history.current.canRedo();
  };

  /**
   * Exports canvas element to a dataurl image. Note that when multiplier is used, cropping is scaled appropriately
   *
   * Available Options are
   * <table style="width:100%">
   *
   * <tr><td><b>Name</b></td><td><b>Type</b></td><td><b>Argument</b></td><td><b>Default</b></td><td><b>Description</b></td></tr>
   * <tr><td>format</td> <td>String</td> <td><optional></td><td>png</td><td>The format of the output image. Either "jpeg" or "png"</td></tr>
   * <tr><td>quality</td><td>Number</td><td><optional></td><td>1</td><td>Quality level (0..1). Only used for jpeg.</td></tr>
   * <tr><td>multiplier</td><td>Number</td><td><optional></td><td>1</td><td>Multiplier to scale by</td></tr>
   * <tr><td>left</td><td>Number</td><td><optional></td><td></td><td>Cropping left offset. Introduced in v1.2.14</td></tr>
   * <tr><td>top</td><td>Number</td><td><optional></td><td></td><td>Cropping top offset. Introduced in v1.2.14</td></tr>
   * <tr><td>width</td><td>Number</td><td><optional></td><td></td><td>Cropping width. Introduced in v1.2.14</td></tr>
   * <tr><td>height</td><td>Number</td><td><optional></td><td></td><td>Cropping height. Introduced in v1.2.14</td></tr>
   *
   * </table>
   *
   * @returns {String} URL containing a representation of the object in the format specified by options.format
   */
  const toDataURL = options => _fc.current.toDataURL(options);

  /**
   * Returns JSON representation of canvas
   *
   * @param propertiesToInclude Array <optional> Any properties that you might want to additionally include in the output
   * @returns {string} JSON string
   */
  const toJSON = propertiesToInclude => {
    if (propertiesToInclude) {
      return _fc.current.toObject(ToJSONFields);
    } else {
      return _fc.current.toObject(ToJSONFields);
    }
  };

  /**
   * Populates canvas with data from the specified JSON.
   *
   * JSON format must conform to the one of fabric.Canvas#toDatalessJSON
   *
   * @param json JSON string or object
   */
  const fromJSON = json => {
    if (!json) return;
    // console.log("got", json)
    let canvas = _fc.current;
    setTimeout(() => {
      canvas.loadFromJSON(json, () => {
        if (currentTool.current === Tool.DefaultTool) {
          canvas.isDrawingMode = canvas.selection = false;
          canvas.forEachObject(o => (o.selectable = o.evented = false));
        }
        canvas.renderAll();
      });
    }, 100);
  };


  /**
   *
   * @param {object} object -contains clear all event data
   * Clear the content of the canvas, this will also clear history but will return the canvas content as JSON to be
     used as needed in order to undo the clear if possible
   */
  const clear = (object) => {
    _fc.current.setViewportTransform([1, 0, 0, 1, 0, 0])
    _fc.current.clear();
    _history.current.clear();
    if (!props.isWhiteboardPlayer) {
      _fc.current.fire('canvas:objects:cleared', object);
    }

    props.calculateNumberOfPages()
  };

  const hasSelection = () => {
    let canvas = _fc.current;
    return !!canvas.getActiveObject();
  };

  const constclearSelection = () => {
    let canvas = _fc.current;
    canvas.discardActiveObject();
    canvas.requestRenderAll();
  };


  const playAudioVideoTag = () => {
    let canvas = _fc.current;
    let activeObj = canvas.getActiveObject();
    // console.log(activeObj)
    // console.log('viewport', canvas.viewportTransform[4], canvas.viewportTransform[5])
    // console.log(canvas.getWidth(), canvas.getHeight())

    let left = activeObj.left * canvas.viewportTransform[0] - (-canvas.viewportTransform[4])
    let top = activeObj.top * canvas.viewportTransform[0] - (-canvas.viewportTransform[5])
    // console.log('calculated left top', left, top)
    // console.log(' left top', activeObj.left, activeObj.top)
    // console.log('viewport', canvas.viewportTransform)
    if (activeObj) {
      if (activeObj.objectType === 'audio') {
        props.openMediaPlayerAndSendEvent(activeObj.mediaFileUrl, 'audio', left, top, activeObj.height * activeObj.scaleY * canvas.viewportTransform[0], activeObj.width * activeObj.scaleX * canvas.viewportTransform[0], activeObj.objectID)
      }
      else if (activeObj.objectType === 'video') {
        props.openMediaPlayerAndSendEvent(activeObj.mediaFileUrl, 'video', left, top, activeObj.height * activeObj.scaleY * canvas.viewportTransform[0], activeObj.width * activeObj.scaleX * canvas.viewportTransform[0], activeObj.objectID)
      }
    }
  }

  /**
  * send selected object to the back of the canvas
  */
  const bringToFront = () => {
    let canvas = _fc.current;
    // let activeObj = canvas.getActiveObject();
    let activeObjects = canvas.getActiveObjects();
    // console.log(activeObjects)
    for (let obj of activeObjects) {
      //currently we dont delete any image on the canvas using the eraser tool
      canvas.bringObjectToFront(obj)
      canvas.renderAll()

      props.sendLessonEventViaWebSocket({
        eventType: 'bringToFront',
        eventData: {
          'sentBy': props.clientID,
          'objectID': obj.objectID
        }
      })
      // canvas.fire("object:modified", { target: obj });
    }

  }

  /**
 * send selected object to the back of the canvas
 */
  const sendToBack = () => {
    let canvas = _fc.current;
    // let activeObj = canvas.getActiveObject();
    let activeObjects = canvas.getActiveObjects();
    // console.log(activeObjects)
    for (let obj of activeObjects) {
      // console.log(JSON.stringify(obj))
      //currently we dont delete any image on the canvas using the eraser tool
      canvas.sendObjectToBack(obj)
      canvas.renderAll()

      props.sendLessonEventViaWebSocket({
        eventType: 'sendToBack',
        eventData: {
          'sentBy': props.clientID,
          'objectID': obj.objectID
        }
      })
      // canvas.fire("object:modified", { target: obj });
    }
    // console.log(JSON.stringify(canvas.getActiveObject()))
  }

  /**
   * Clone selected object from the canvas
   */
  const cloneObject = () => {
    let canvas = _fc.current;
    console.log(fabric)

    var object = canvas.getActiveObject();
    let newObj = object.toObject(ToJSONFields)
    // object.clone(function (clone) {
    //   canvas.add(clone.set({
    //     left: object.left + 10,
    //     top: object.top + 10,
    //     "objectID": uuid4(),
    //     "clientID": undefined,
    //   }));
    // })
    newObj.top = object.top + 10
    newObj.left = object.left + 10
    newObj.objectID = uuid4();
    newObj.clientID = undefined;

    const newObjString = JSON.stringify(newObj);
    // newObj.set("top", object.top + 10);
    // newObj.set("left", object.left + 10);
    // newObj.set("objectID", uuid4());
    // newObj.set("clientID", undefined);
    // console.log(newObj)
    // console.log(newObj)
    addObj(JSON.parse(newObjString))
    // canvas.add(newObj)
    // canvas.renderAll()
  }

  const hideObject = () => {
    let canvas = _fc.current;
    let activeObj = canvas.getActiveObject();
    activeObj.notVisibleTo = ['Student'];
    canvas.renderAll()
    canvas.fire("object:modified", { target: activeObj });
  }

  const changeObjectColor = (colorCode) => {
    let canvas = _fc.current;
    let activeObj = canvas.getActiveObject();
    if (activeObj.__originalState && checkTypesOfCanvasObject(activeObj.__originalState.type)) {
      // console.log('setting color')
      activeObj.set("stroke", colorCode);
    }
    console.log(colorCode)
    // console.log(activeObj)
    canvas.renderAll()
    canvas.fire("object:modified", { target: activeObj });
  }

  const showObject = () => {
    let canvas = _fc.current;
    let activeObj = canvas.getActiveObject();
    activeObj.notVisibleTo = null;
    canvas.renderAll()
    canvas.fire("object:modified", { target: activeObj });
  }

  const openPopupForOptions = (event) => {
    let canvas = _fc.current;
    let activeObj = canvas.getActiveObject();
    const mouseX = event.clientX;
    const mouseY = event.clientY;

    const mouseRect = {
      top: mouseY,
      left: mouseX,
      right: mouseX,
      bottom: mouseY,
      width: 0,
      height: 0,
    };

    setOpenOptions(true);
    setOptionsAnchor({ getBoundingClientRect: () => mouseRect, nodeType: 1 });
    setSelectedObject(activeObj);

  }

  /**
 * Remove selected object from the canvas
 */
  const removeSelected = () => {
    let canvas = _fc.current;
    let activeObjects = canvas.getActiveObjects();
    console.log(activeObjects)
    for (let obj of activeObjects) {
      // console.log(obj)
      obj.set("__removed", true);
      obj.set("removedBy", props.clientID)
      obj.set('left', obj.eraserLeft)
      obj.set('top', obj.eraserTop)
      canvas.remove(obj);

    }
    canvas.discardActiveObject();
    canvas.requestRenderAll();
    // if (activeObj) {
    //   let selected = [];
    //   if (activeObj.type === 'activeSelection') {
    //     activeObj.forEachObject(obj => selected.push(obj));
    //   } else {
    //     selected.push(activeObj);
    //   }
    //   console.log("selected objects",activeObj,selected)
    //   selected.forEach(obj => {
    //     obj.__removed = true;
    //     obj.set("removedBy", props.clientID)
    //     let objState = obj.toObject(ToJSONFields);
    //     obj.__originalState = objState;
    //     let state = JSON.stringify(objState);
    //     _history.current.keep([obj, state, state]);
    //     canvas.remove(obj);
    //   });


  };

  /**
* copy selected object from the canvas
*/
  const copy = () => {
    let canvas = _fc.current;
    canvas.getActiveObject().clone(cloned => (_clipboard.current = cloned));
  };

  /**
* paste selected object from the canvas
*/
  const paste = () => {
    // clone again, so you can do multiple copies.
    // _clipboard.clone(clonedObj => {
    //   let canvas = _fc.current;
    //   canvas.discardActiveObject();
    //   clonedObj.set({
    //     left: clonedObj.left + 10,
    //     top: clonedObj.top + 10,
    //     evented: true
    //   });
    //   if (clonedObj.type === 'activeSelection') {
    //     // active selection needs a reference to the canvas.
    //     clonedObj.canvas = canvas;
    //     clonedObj.forEachObject(obj => canvas.add(obj));
    //     clonedObj.setCoords();
    //   } else {
    //     canvas.add(clonedObj);
    //   }
    //   _clipboard.top += 10;
    //   _clipboard.left += 10;
    //   canvas.setActiveObject(clonedObj);
    //   canvas.requestRenderAll();
    // });
  };

  /**
   * Sets the background from the dataUrl given
   *
   * @param dataUrl the dataUrl to be used as a background
   * @param options
   */
  const setBackgroundFromDataUrl = (dataUrl, options = {}) => {
    let canvas = _fc.current;
    let img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');
    const { stretched, stretchedX, stretchedY, ...fabricOptions } = options;
    img.onload = () => {
      const imgObj = new fabric.Image(img);
      if (stretched || stretchedX) imgObj.scaleToWidth(canvas.width);
      if (stretched || stretchedY) imgObj.scaleToHeight(canvas.height);
      canvas.setBackgroundImage(
        imgObj,
        () => canvas.renderAll(),
        fabricOptions
      );
    };
    img.src = dataUrl;
  };

  /**
   * Add polyline object to canvas
   */
  const addPolyline = (color) => {
    let canvas = _fc.current;
    let hexagonSizeX = 100
    let hexagonSizeY = 180
    let points = [
      { x: 2 * hexagonSizeX, y: 0 },
      { x: hexagonSizeX, y: hexagonSizeY },
      { x: -hexagonSizeX, y: hexagonSizeY },
      { x: -hexagonSizeX * 2, y: 0 },
      { x: -hexagonSizeX, y: -hexagonSizeY },
      { x: hexagonSizeX, y: -hexagonSizeY },
      { x: hexagonSizeX * 2, y: 0 },
    ];

    // Initiating a polyline object
    let polyline = new fabric.Polyline(points);

    // Set the properties
    polyline.set("stroke", color);
    polyline.set("strokeWidth", 2);
    polyline.set("fill", color);
    polyline.set("scaleX", 0.75);
    polyline.set("scaleY", 0.75);
    let opts = {
      left: (canvas.getWidth() - polyline.width) * 0.5,
      top: (canvas.getHeight() - polyline.height) * 0.5,


    };
    var viewportTransform = canvas.viewportTransform;
    polyline.set({
      left: opts.left - viewportTransform[4],
      top: opts.top - viewportTransform[5]
    });
    // Adding it to the canvas

    canvas.viewportCenterObject(polyline)
    canvas.add(polyline);
    canvas.renderAll()
  }

  /**

  /**
 * Add right angled triangle object to canvas
 */
  const addRightAngledTriangle = (color) => {
    let canvas = _fc.current;
    var points = [
      { x: 0, y: -300 }, { x: -300, y: 0 }, { x: 0, y: 0 }
    ];

    // Initiating a polyline object
    let polyline = new fabric.Polyline(points);

    // Set the properties
    // polyline.set("stroke", 'red');
    polyline.set("strokeWidth", 2);
    polyline.set("fill", color);
    polyline.set("scaleX", 0.75);
    polyline.set("scaleY", 0.75);
    let opts = {
      left: (canvas.getWidth() - polyline.width) * 0.5,
      top: (canvas.getHeight() - polyline.height) * 0.5,


    };
    let viewportTransform = canvas.viewportTransform;
    polyline.set({
      left: opts.left - viewportTransform[4],
      top: opts.top - viewportTransform[5]
    });
    // Adding it to the canvas

    canvas.viewportCenterObject(polyline)
    canvas.add(polyline);
    canvas.renderAll()
  }

  /**
   * Add textboxe object to canvas
   * @param {string} text -initial text to be added to textbox
   * @param {object} options -any specific properties can be added by using options parameter when calling this function
   */

  const addText = (text, options = {}) => {

    // console.log("options " + JSON.stringify(options))
    let canvas = _fc.current;

    let iText = new fabric.IText(text, {
      backgroundColor: 'white',
      fontFamily: 'Helvetica',
      fill: '#333',
      lineHeight: 1.1,
      textBackgroundColor: 'white',
      fontSize: 25,
      objectType: 'text-box',
      borderRadius: '10px',

    });

    // console.log("itext calc transformation" + iText.calcTransformMatrix())
    let opts = {
      left: (canvas.getWidth() - iText.width) * 0.5,
      top: (canvas.getHeight() - iText.height) * 0.5,


    };
    // console.log("the opts are " + JSON.stringify(opts))

    let viewportTransform = canvas.viewportTransform;
    Object.assign(options, opts);


    // iText.set({
    //   left:  centerPointOnCanas.x,
    //   top: centerPointOnCanas.y
    // });


    canvas.add(iText);
    canvas.viewportCenterObject(iText)
    canvas.setActiveObject(iText);
    canvas.renderAll();

  };

  /**
   *
   * @param {uuid} objectID -unique id of objects on the canvas
   * @returns {array} - returns object with the objectid provided as params inside an array
   */
  const getObjectById = objectID => {
    var objs = _fc.current.getObjects();
    var arr = []
    for (var i = 0, len = objs.length; i < len; i++) {
      if (objs[i].objectID === objectID) {
        arr.push(objs[i]);
      }
    }
    return arr;
  };

  /**
 *
 * @param {uuid} objectID -unique id of objects on the canvas
 * Removes the object from the canvas using the object id priovided in the params
 */

  const removeObjectById = (objectID) => {
    let canvas = _fc.current;
    var existingObjects = getObjectById(objectID);
    // console.log('removing object')
    for (var obj of existingObjects) {
      obj.__removed = true;
      obj.set("removedBy", props.clientID)

      canvas.remove(obj);
      canvas.renderAll();
    }
  }


  /**
*
* @param {object} object - data of object which has been modified
* Removes the object from the canvas using the object id priovided in the params
* This function is called when any  modification is received
  from another client.When we set an object in the current canvas [object:modify] is not fired
*  Any other modification done through UI by this client will fire [object:modify] which will lead to the
   a call to onObjectModified. In that function we can assume that the object was modified by this client
*/

  const modifyObj = (obj, sendEvent = false, receivedFromEvent = false) => {
    // console.log(obj, 'received')
    let canvas = _fc.current;

    let isTeacher = props.isTeacher

    fabric.util.enlivenObjects([obj], {})
      .then(objs => {

        objs.forEach(function (object) {
          // console.log(object)
          let objects = canvas.getObjects()
          for (let i = 0; i < objects.length; ++i) {
            if (objects[i].objectID === object.objectID) {
              // console.log('changed')
              // console.log(obj, 'received 2')
              // console.log(object, 'enliven')
              // console.log(objects[i], 'orginal')
              // objects[i] = object

              objects[i].set("dirty", true);
              Object.assign(objects[i], object)
              objects[i].transparentCorners = false;
              objects[i].cornerColor = THEME_OBJECT_BORDER;
              objects[i].cornerStyle = 'circle';
              objects[i].setCoords()
              // console.log(objects[i], 'assigned')
              if (object.notVisibleTo) {
                if (!isTeacher) {
                  if (object.notVisibleTo.includes('Student')) {
                    // console.log('invisible object')
                    objects[i].set("visible", false)
                  }
                }
              }
              if (object.objectType === 'iframe') {
                let iframeElement = document.getElementById(object.objectID)
                // console.log(document.getElementById(obj.objectID))
                let positionDimensionObj = window.findObjectAndGetPositionAndDimensions(object.objectID)
                // console.log(positionDimensionObj)
                iframeElement.style.top = positionDimensionObj.top + 'px'
                iframeElement.style.left = positionDimensionObj.left + 'px'
                iframeElement.style.width = positionDimensionObj.width + 'px'
                iframeElement.style.height = positionDimensionObj.height + 'px'
                if (object.iframeType) {
                  if (object.iframeType === 'pdfViewer') {
                    let iframe = document.getElementById(object.objectID + '-iframe');
                    // console.log(iframe.contentWindow)
                    iframe.contentWindow.changePdfViewerDimension(parseInt((iframeElement.style.height).substring(0, (iframeElement.style.height).length - 2)), parseInt((iframeElement.style.width).substring(0, (iframeElement.style.width).length - 2)))
                  }
                }
              }
              // objects[i].setCoords()
              if (objects[i].__originalState && (checkTypesOfCanvasObject(objects[i].__originalState.type))) {
                console.log('setting color')
                objects[i].set("stroke", object.stroke);
              }
              if (sendEvent) {
                _onObjectModified({
                  target: objects[i]
                })
              }
              if (receivedFromEvent) {
                // console.log(objects[i])
                addReceivedModifiedObjectToUndoList(objects[i])
                // _history.current.keep(objects[i].toObject(ToJSONFields));
              }
              // console.log(objects[i])
              canvas.renderAll()
            }
          }
        })
        canvas.renderAll()
        setActionBox()
      })

    // }

    // console.log(object)
    // }
    // canvas.fire("object:modified", { target: modifiedObj });
    // else {
    //   removeObj(object);
    //   addObj(object);
    // }
    //existingObj.set(object)
    //if(existingObj.type=="line"){
    //  existingObj.top = object.top
    //  existingObj.left = object.left
    //}
    //console.log("received object for modification1",existingObj)
    //existingObj.set('dirty', true);

    //existingObj.setCoords();
    //canvas.requestRenderAll();
    //canvas.calcOffset();
    //_recordInHistoryOnObjectModificationReceived({ target: existingObj });
    // canvas.fire("object:modified",{target:existingObj});

    //  console.log("🚀 ~ file: SketchField.jsx ~ line 670 ~ SketchField ~ existingObj", existingObj)
    // console.log("🚀 ~ file: SketchField.jsx ~ line 682 ~ SketchField ~ existingObj", existingObj)
    // console.log("🚀 ~ file: SketchField.jsx ~ line 682 ~ SketchField ~ existingObj", existingObj)
  };

  /**
   *
   * @param {uuid} objectId - unique id of object
   * @param {number} left - horizontal  distance of object from the origin of canvas
   * @param {number} top - horizontal  distance of object from the origin of canvas
   * @param {number} height - height of the object
   * @param {number} width - width of the object
   * This function changes the position and dimensions of the object when it is dragged on the whiteboard
   */
  const changeObjectPositionOnDrag = (objectId, left, top, height, width) => {
    // console.log(objLeft, objTop, objectId)
    let canvas = _fc.current
    let objects = canvas.getObjects()
    let modifiedObj;
    for (let i = 0; i < objects.length; ++i) {
      if (objects[i].objectID === objectId) {
        if (left && left !== '') {
          // console.log('lefty')
          objects[i].left = (parseInt(left.replace('px', '')) + (-canvas.viewportTransform[4])) / canvas.viewportTransform[0]
        }
        if (top && top !== '') {
          // console.log('top')
          objects[i].top = (parseInt(top.replace('px', '')) + (-canvas.viewportTransform[5])) / canvas.viewportTransform[0]
        }
        if (height) {
          // console.log('height', (parseInt(height.replace('px', ''))) / (canvas.viewportTransform[0] * objects[i].scaleY))
          objects[i].height = (parseInt(height.replace('px', ''))) / (canvas.viewportTransform[0] * objects[i].scaleY)
        }
        if (width) {
          // console.log('width', (parseInt(width.replace('px', ''))) / (canvas.viewportTransform[0] * objects[i].scaleX))
          objects[i].width = (parseInt(width.replace('px', ''))) / (canvas.viewportTransform[0] * objects[i].scaleX)
        }


        // console.log('heyy', objects[i])
        modifiedObj = objects[i]
      }
    }
    // console.log('iframe id', modifiedObj.objectID)
    canvas.fire("object:modified", { target: modifiedObj });
    canvas.renderAll()
  }

  /**
*
* @param {object} object - data of object which has been added
* adds the object to the canvas and applies the specific properties of objects using their objectTypes
*/

  const addObj = object => {
    // console.log(
    //   '🚀addObj ~SketchField.jsx ',
    //   object
    // );
    // console.log("adding obj", object)
    let canvas = _fc.current;
    // console.log(this)
    let onClickAudioVideoRef = onClickAudioVideo
    let onClickIframeRef = onClickIframe
    let onClickPdfViewerRef = onClickPdfViewer
    let removeObjectByIdRef = removeObjectById
    let changeObjectPositionOnDragRef = changeObjectPositionOnDrag
    let findDimension = props.findObjectAndGetPositionAndDimensions
    let whiteBoardID = props.whiteBoardID
    let accessToken = props.accessToken
    let clientID = props.clientID


    fabric.util.enlivenObjects([object], {})
      .then(objs => {
        let origRenderOnAddRemove = canvas.renderOnAddRemove;

        canvas.renderOnAddRemove = true;

        objs.forEach(function (o) {
          // console.log(o)
          canvas.add(o);
          if (o.notVisibleTo) {
            if (!props.isTeacher) {
              if (o.notVisibleTo.includes('Student')) {
                // console.log('invisible object')
                o.visible = false
              }
            }

          }
          if (o.objectType) {
            if (o.objectType === 'audio' || o.objectType === 'video') {
              // console.log('audio video')
              o.subTargetCheck = true
              o.on('mousedown', onClickAudioVideoRef);
            }
            else if (o.objectType === 'iframe') {
              o.subTargetCheck = true;
              o.on('mousedown', onClickIframeRef);
              let positionDimensionObj = findDimension(o.objectID)
              // console.log('props', o.iframeType, clientID, whiteBoardID, accessToken)
              createIframeUsingJavascript(positionDimensionObj, o.objectID, o.mediaFileUrl, changeObjectPositionOnDragRef, o.iframeType, clientID, whiteBoardID, accessToken)
              document.getElementById(o.objectID + '-close').addEventListener('click', function (e) {
                e.preventDefault();
                document.getElementById(o.objectID).remove();
                removeObjectByIdRef(o.objectID)
              }, false)
            }
            else if (o.objectType === 'pdfToView') {
              o.subTargetCheck = true
              o.on('mousedown', onClickPdfViewerRef);

            }
          }
          if (o.isPdf)
            canvas.sendObjectToBack(o)
        });

        canvas.renderOnAddRemove = origRenderOnAddRemove;
        canvas.renderAll();
      });
    // canvas.renderAll();
    // Make sure to call this once you're ready!

  };

  /**
*
* @param {object} object - data of object which has to be removed
* removes the object from the canvas
*/

  const removeObj = object => {
    let canvas = _fc.current;
    var existingObjects = getObjectById(object.objectID);
    for (var existingObj of existingObjects) {
      existingObj.set('__removed', true);
      existingObj.lastAction = object.lastAction ? object.lastAction : null
      existingObj.removedBy = object.removedBy;
      // console.log(existingObj)
      canvas.remove(existingObj);
      // _onObjectRemoved({target:existingObj})
      // canvas.fire("object:removed",{target:existingObj});
      canvas.renderAll();
    }

  };


  function removePeerObjectsBySocketId(peerSocketId) {

    let canvas = _fc.current;
    const existingObjects = canvas.getObjects();
    for (var existingObj of existingObjects) {
      if (existingObj["peerSocketId"] === peerSocketId)
        canvas.remove(existingObj);
      // _onObjectRemoved({target:existingObj})
      // canvas.fire("object:removed",{target:existingObj});
      canvas.renderAll();
    }



  }

  const callEvent = (e, eventFunction) => {
    if (_selectedTool.current) eventFunction(e);
  };




  function createPath(startX, startY, endX, endY, strokeColor, strokeWidth, peerSocketId, brush) {
    createAnotherPath(startX, startY, endX, endY, strokeColor, strokeWidth, peerSocketId, brush)
  }



  function createAnotherPath(startX, startY, endX, endY, strokeColor, strokeWidth, peerSocketId, brush) {

    let pointera = { x: startX, y: startY }
    let pointerb = { x: endX, y: endY }

    let optionsa = { pointera, e: {} };
    let optionsb = { pointerb, e: {} };

    //brush.onMouseDown(pointera,{e:{}});

    brush.onMouseMove(pointerb, { e: {} });

  }

  useEffect(() => {
    let { tool, value, undoSteps, defaultValue, backgroundColorObject, height, width } = props;
    // console.log(props)
    const canvas = new fabric.Canvas(_canvas.current, {
      preserveObjectStacking: true,
      renderOnAddRemove: false,
      stateful: true,
      selection: !props.isWhiteboardPlayer,
      allowTouchScrolling: true,
      isDrawingMode: !props.isWhiteboardPlayer,
      skipTargetFind: props.isWhiteboardPlayer,

    });

    // console.log("the selected tool is" + canvas);
    _fc.current = canvas
    // console.log("the selected tool is" + _container.current);
    props.setIsCanvasInitialized(true)
    _resizeWindow(null, window.screen.width, window.screen.height)

    // set initial backgroundColor
    changeBackgroundColor(backgroundColorObject);

    // Control resize
    window.addEventListener('resize', (e) => {
      // console.log('resizing')
      _resizeWindow(e)
    });

    // Initialize History, with maximum number of undo steps

    // Events binding
    if (!props.isWhiteboardPlayer) {
      canvas.on('object:added', _onObjectAdded);
      canvas.on('before:path:created', _onBeforePathCreated);
      canvas.on('object:modified', _onObjectModified);
      canvas.on('object:removed', _onObjectRemoved);
      canvas.on('canvas:objects:cleared', _onCanvasCleared);
      canvas.on('mouse:down', _onMouseDown);
      canvas.on('mouse:move', _onMouseMove);
      canvas.on('mouse:up', _onMouseUp);
      canvas.on('mouse:out', _onMouseOut);
      canvas.on('object:moving', _onObjectMoving);
      canvas.on('object:scaling', _onObjectScaling);
      canvas.on('backgroundColor:changed', _onBackgroundColorChanged);
      canvas.on('object:rotating', _onObjectRotating);
      canvas.on('pan:changed', _onPanChanged);
      canvas.on('mouse:dblclick', _onMouseDoubleClick);
      canvas.on('newZoom:changed', _onNewZoomChanged);
      canvas.on('selection:created', _onSelection);
      canvas.on('selection:updated', _onSelectionChanged);
      canvas.on('selection:cleared', _onSelectionCleared);
    }
    // canvas.on("text:editing:entered", console.log)
    // canvas.on("text:editing:exited", console.log)

    // disableTouchScroll();

    // _resize();

    // initialize canvas with controlled value if exists

    if (value || defaultValue) {
      fromJSON(value || defaultValue);
    }
    // console.log("the selected tool is" + _fc.current)
    return () => {
      window.removeEventListener('resize', _resizeWindow)
      canvas.dispose();
      props.setIsCanvasInitialized(false)
    }
  }, [])


  // useEffect(() => {
  //   _resize();
  // }, [props.width, props.height])





  useEffect(() => {
    fromJSON(props.value);
  }, [props.value])


  window.addImgToSketch = addImg
  let { className, style, width, height } = props;

  console.log('sketch rerendering')


  return (

    <div
      ref={_container}
      style={{
        height: height,
        width: width,
        position: 'relative',
        overflow: 'hidden'
      }}
    >

      {
        <Box
          id='object-actions-box'
          sx={{

            position: 'absolute',
            display: 'none',
            zIndex: 2
          }}
        >
          <Box sx={{
            backgroundColor: THEME_COLOR,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '5px',
            justifyContent: 'left',
            textAlign: 'left',
            alignItems: 'left',
            padding: '5px 5px',
          }}>

            <Tooltip title='Delete' placement='top'>
              <IconButton sx={{ color: '#ffffff' }}
                size='medium'
                onClick={() => {
                  removeSelected()
                  document.getElementById('object-actions-box').style.display = 'none'
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            {/* <Tooltip title='Take Screenshot' placement='top'>
              <IconButton sx={{ color: '#ffffff' }}
                size='medium'
                onClick={() => {
                  const screenshotTarget = document.getElementById('whiteboard-screenshot-box')

                  html2canvas(screenshotTarget, {
                    scale: 1
                  }).then(async (canvas) => {
                    const dataurl = canvas.toDataURL("image/png")
                    const link = document.createElement('a');
                    link.href = dataurl;

                    // Set the download attribute with the desired file name
                    link.download = 'screenshot.png';

                    link.click();

                    // Optionally, you can remove the element from the DOM after the click
                    link.remove();

                  });

                }}
              >
                <ScreenshotMonitorIcon />
              </IconButton>
            </Tooltip> */}
            {!selectionTypeGroup && <>
              {showObjectButton ? <Tooltip title='Show' placement='top'>
                <IconButton sx={{ color: '#ffffff' }}
                  size='medium'
                  onClick={() => {
                    showObject()
                    setShowObjectButton(false)

                    // document.getElementById('object-actions-box').style.display = 'none'
                  }}
                >
                  <VisibilityOffIcon />
                </IconButton>
              </Tooltip> :
                <Tooltip title='Hide' placement='top'>
                  <IconButton sx={{ color: '#ffffff' }}
                    size='medium'
                    onClick={() => {
                      hideObject()
                      setShowObjectButton(true)
                      // document.getElementById('object-actions-box').style.display = 'none'
                    }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>}
              {showColorButton && <Tooltip title='Change Color' placement='top'>
                <IconButton sx={{ color: '#ffffff' }}
                  size='medium'
                  onClick={(e) => {
                    setOpenObjectColorPicker(e.currentTarget)

                  }}
                >
                  <PaletteIcon />
                </IconButton>
              </Tooltip>}
              <Popover
                open={openObjectColorPicker}
                anchorEl={openObjectColorPicker}
                onClose={() => {
                  setOpenObjectColorPicker(false)
                }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >


                <TwitterPicker
                  sx={{ width: '100%' }}
                  colors={colors}
                  id="objectColorPicker"
                  onChange={(color) => {
                    changeObjectColor(color.hex)
                    setOpenObjectColorPicker(false)
                  }}
                />
              </Popover>
              <Tooltip title='Bring To Front' placement='top'>
                <IconButton sx={{ color: '#ffffff' }}
                  size='medium'
                  onClick={() => {
                    bringToFront()
                    // document.getElementById('object-actions-box').style.display = 'none'
                  }}
                >
                  <img
                    alt='bringToFront' height='30px' width='25px'
                    src="/bringToForward_icon.png"
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title='Send To Back' placement='top'>
                <IconButton sx={{ color: '#ffffff' }}
                  size='medium'
                  onClick={() => {
                    sendToBack()
                    // document.getElementById('object-actions-box').style.display = 'none'
                  }}
                >
                  <img
                    alt='sendToBack' height='30px' width='25px'
                    src="/sendToBackward_icon.png"
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title='Clone' placement='top'>
                <IconButton sx={{ color: '#ffffff' }}
                  size='medium'
                  onClick={() => {
                    cloneObject()
                    // document.getElementById('object-actions-box').style.display = 'none'
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>

            </>}
          </Box>
        </Box>
      }
      <canvas
        id={uuid4()}
        ref={_canvas}
      // height={window.screen.height}
      //  width={window.screen.width}
      >
        Sorry, Canvas HTML5 element is not supported by your browser :(
      </canvas>

      {/* <Box
        ref={c => (_container.currentLayer = c)} sx={{width:"100vw",height:"100vh",position:"fixed",left:"0px", top :"0px"}}>

      <canvas id={uuid4()} ref={c => (_canvasLayer = c)}>
          Sorry, Canvas HTML5 element is not supported by your browser :(
        </canvas>
      </Box>  */}


    </div>



  );
};

// SketchField.defaultProps = {
//   lineColor: 'black',
//   lineWidth: 10,
//   fillColor: 'transparent',
//   backgroundColorObject: { color: 'transparent' },
//   opacity: 1.0,
//   undoSteps: 25,
//   tool: Tool.Pencil,
//   widthCorrection: 0,
//   heightCorrection: 0,
//   forceValue: false,
//   onObjectAdded: () => null,
//   onPathCreated: () => null,
//   onObjectModified: () => null,
//   onObjectRemoved: () => null,
//   onMouseDown: () => null,
//   onMouseMove: () => null,
//   onMouseUp: () => null,
//   onMouseOut: () => null,
//   onObjectMoving: () => null,
//   onObjectScaling: () => null,
//   onObjectRotating: () => null,
//   onBeforePathCreated: () => null,
// };

export default React.forwardRef(SketchField)
