import React, { useState } from "react";
import { Box, Button, IconButton, OutlinedInput, Popover, Tooltip } from "@mui/material";

import { sendChatMessage } from "./src/service/slateService";
import { FRONTEND_URL, TECH_SUPPORT_SLATE_ID, THEME_COLOR, THEME_HOVER_COLOR } from "./src/config/constants";
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from 'uuid';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const TechSupport = (props) => {
    const [openMessageBox, setOpenMessageBox] = useState(false);
    const [message, setMessage] = useState('');
    const [openSendMessageNotification, setOpenSendMessageNotification] = useState(false);

    const handleTechSupport = async (issue) => {

        let techSupportSlateId = TECH_SUPPORT_SLATE_ID;
        let status, res;
        let messageId = uuidv4();
        let messageToSend = 'Session Id: ' + props.whiteBoardID + '$%$%$' + "Username: " + props.userName + '$%$%$' + 'Issue: ' + issue + '$%$%$' + 'Slate Name: ' + props.slateRef.current.title + '$%$%$' + 'Slate Link: ' + FRONTEND_URL + 'slate/' + props.slateRef.current.id;
        console.log('trying')
        try {
            res = await sendChatMessage(props.accessToken, techSupportSlateId, messageToSend, null, 'text', null, messageId)
            status = res.status
            // console.log(res)
        }
        catch (err) {
            console.log(err)
            // status = err.response.status
        }
        if (status === 200) {
            console.log('sent')
            setOpenSendMessageNotification(true)
        }
        setMessage('')
    }

    const handleCloseSendMessageNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSendMessageNotification(false);
    };


    return <>
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            // sx={{
            //     backgroundColor: THEME_COLOR,
            //     color: '#ffffff', 
            // }}
            style={{ top: '90px' }}
            open={openSendMessageNotification} autoHideDuration={3000} onClose={handleCloseSendMessageNotification}>
            <Alert onClose={handleCloseSendMessageNotification} sx={{
                width: '100%', backgroundColor: THEME_COLOR,
                color: '#ffffff',
            }}>
                Your issue has been submitted to the technical team!
            </Alert>
        </Snackbar>
        <Tooltip title="Tech Support">
            <IconButton
                sx={{
                    display: { xs: 'none', sm: 'block', md: 'block', lg: 'block' },
                    color: THEME_COLOR,
                    marginRight: '20px',
                    borderRadius: '5px',
                    border: '1.5px solid #D3D3D3',
                    marginTop: { xs: "20px", sm: '10px', md: "10px", lg: '10px', xl: '10px' },
                    marginBottom: { xs: "20px", sm: '10px', md: "10px", lg: '10px', xl: '10px' },
                    width: { xs: '20px', sm: '40px', md: '40px', lg: '60px' }
                }}
                size='small'
                onClick={(e) => {
                    setOpenMessageBox(e.currentTarget)

                }}
            >
                <HelpCenterIcon sx={{ fontSize: { xs: "0.75rem", sm: '1.5rem', md: "1.5rem", lg: '1.5rem', xl: '1.5rem' } }} />
            </IconButton>
        </Tooltip>
        <Popover
            open={openMessageBox}
            anchorEl={openMessageBox}
            onClose={() => {
                setOpenMessageBox(false)
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <form onSubmit={(e) => {
                e.preventDefault()
                handleTechSupport(message)
                setOpenMessageBox(false)
            }}>
                <Box sx={{
                    backgroundColor: '#fafafa',
                    border: '1px solid #e0e0e0',
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '5px 10px',
                    width: '400px',
                    boxShadow: '2',
                    position: 'relative'
                }}>
                    <IconButton
                        sx={{ color: 'black', position: 'absolute', zIndex: 10000, top: 0, right: 0 }}
                        onClick={() => {
                            setOpenMessageBox(false)
                            setMessage('')
                        }}
                        size="small">
                        <CloseIcon sx={{ fontSize: '1rem' }} />
                    </IconButton>

                    <Box sx={{
                        paddingTop: '10px', display: 'flex', justifyContent: 'center', width: '80%', paddingBottom: '10px', marginRight: '10px'
                    }}>
                        <OutlinedInput
                            sx={{
                                width: '100%', borderRadius: '40px',
                                '& .MuiAutocomplete-inputRoot': {
                                    borderRadius: '15px',
                                },
                            }}
                            onFocus={() => {
                                props.isSearchFocus.current = true;
                                // console.log(props.isSearchFocus.current)
                            }}
                            onBlur={() => {
                                props.isSearchFocus.current = false;
                                // console.log(props.isSearchFocus.current)
                            }}
                            id='message-lesson'
                            type="text"
                            placeholder="Describe the issue"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            name="message" required
                        />

                    </Box>
                    <Box sx={{
                        paddingTop: '20px', display: 'flex', justifyContent: 'center', width: '15%', paddingBottom: '20px'
                    }}>

                        <Button
                            type="submit"
                            variant="contained"
                            sx={{
                                background: THEME_COLOR,
                                '&:hover': {
                                    backgroundColor: THEME_HOVER_COLOR,
                                    boxShadow: 'none',
                                },
                            }}
                        >
                            <SendIcon />
                        </Button>
                    </Box>

                </Box>
            </form>
        </Popover>
    </>
}

export default TechSupport;